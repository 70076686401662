<template>
  <div class="mx-auto mt-6 file-input-width">
    <v-card>
      <v-data-table
        v-model="wsService.selectedWs"
        :items="wsService.wsTemplate.ws_layers"
        :headers="headers"
        :single-select="false"
        item-key="name"
        show-select
        class="elevation-0 pa-5"
        dense
      >
        <template v-slot:[`item.styles`]="{ item }">
          <v-select
            v-model="item.layer_style"
            :items="item.styles"
            placeholder="Style"
            :hint="item.defaultStyle"
            hide-details
            class="mt-5"
            solo
            dense
          />
          <small class="text--disabled">
            Default: <b>{{ item.defaultStyle }}</b>
          </small>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      headers: [],
      stylesHeader: {
        text: 'Styles',
        align: 'start',
        sortable: false,
        value: 'styles'
      }
    };
  },
  computed: {
    ...mapState(['wsService', 'geoserver_data'])
  },
  async mounted() {
    this.headers = this.geoserver_data.layerHeaders.concat(this.stylesHeader);
  }
};
</script>
